import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { OtherLayoutComponent } from './layouts/other-layout/other-layout.component';

const routes: Routes =[
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '', component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
      }
    ]
  },
  {
    path: '', component: OtherLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/layouts/other-layout/other-layout.module').then(m => m.OtherLayoutModule)
      }
    ]
    
  },
  {
    path: '**', redirectTo: '/login'
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
