import { Injectable } from '@angular/core';
import { Constants } from '../variables/constants';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor() { }
  signOut(): void {
    window.sessionStorage.clear();
  }
  public saveToken(token: string): void {
    window.sessionStorage.removeItem(Constants.TOKEN_KEY);
    window.sessionStorage.setItem(Constants.TOKEN_KEY, token);
  }
  public getToken(): string | null {
    return window.sessionStorage.getItem(Constants.TOKEN_KEY);
  }
  public saveUser(user: any): void {
    window.sessionStorage.removeItem(Constants.USER_KEY);
    window.sessionStorage.setItem(Constants.USER_KEY, JSON.stringify(user));
  }
  public getUser(): any {
    const user = window.sessionStorage.getItem(Constants.USER_KEY);
    if (user) {
      return JSON.parse(user);
    }
    return {};
  }
}
