<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav mode="side" opened [disableClose]="true">
        <mat-toolbar-row (click)="toggleMenu()" style="filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); display: flex; justify-content: center; margin-bottom: 36px;background: #011b4b;">
            <img src="assets/image/shortlogo.png" >
        </mat-toolbar-row>
        <mat-toolbar>
            <mat-toolbar-row class="d-flex justify-content-between align-items-center" routerLinkActive="active" routerLink="/news" (click)="setBreadcrumb('Мэдээ мэдээлэл')">
                <div>
                    <img src="assets/image/icons/news.svg" />&nbsp;&nbsp;&nbsp;Мэдээ мэдээлэл
                </div>
                <div>
                    <img src="assets/image/icons/arrow-right.svg" />
                </div>
            </mat-toolbar-row>
            <mat-toolbar-row class="d-flex justify-content-between align-items-center" routerLinkActive="active" routerLink="/faq" (click)="setBreadcrumb('Түгээмэл асуулт')">
                <div>
                    <img src="assets/image/icons/news.svg" />&nbsp;&nbsp;&nbsp;Түгээмэл асуулт
                </div>
                <div>
                    <img src="assets/image/icons/arrow-right.svg" />
                </div>
            </mat-toolbar-row>
            <mat-toolbar-row class="d-flex justify-content-between align-items-center" routerLinkActive="active" routerLink="/faqdesc" (click)="setBreadcrumb('Түгээмэл асуулт Дэлгэрэнгүй')">
                <div>
                    <img src="assets/image/icons/news.svg" />&nbsp;&nbsp;&nbsp;Түгээмэл асуулт Дэлгэрэнгүй
                </div>
                <div>
                    <img src="assets/image/icons/arrow-right.svg" />
                </div>
            </mat-toolbar-row>
            <mat-toolbar-row class="d-flex justify-content-between align-items-center" routerLinkActive="active" routerLink="/compinfo" (click)="setBreadcrumb('Байгууллагын мэдээлэл')">
                <div>
                    <img src="assets/image/icons/news.svg" />&nbsp;&nbsp;&nbsp;Байгууллагын мэдээлэл
                </div>
                <div>
                    <img src="assets/image/icons/arrow-right.svg" />
                </div>
            </mat-toolbar-row>
            <mat-toolbar-row class="d-flex justify-content-between align-items-center" routerLinkActive="active" routerLink="/team" (click)="setBreadcrumb('Хамт олон')">
                <div>
                    <img src="assets/image/icons/news.svg" />&nbsp;&nbsp;&nbsp;Хамт олон
                </div>
                <div>
                    <img src="assets/image/icons/arrow-right.svg" />
                </div>
            </mat-toolbar-row>
            <mat-toolbar-row class="d-flex justify-content-between align-items-center" routerLinkActive="active" routerLink="/policy" (click)="setBreadcrumb('Бодлого')">
                <div>
                    <img src="assets/image/icons/news.svg" />&nbsp;&nbsp;&nbsp;Бодлого
                </div>
                <div>
                    <img src="assets/image/icons/arrow-right.svg" />
                </div>
            </mat-toolbar-row>
            <mat-toolbar-row class="d-flex justify-content-between align-items-center" routerLinkActive="active" routerLink="/info" (click)="setBreadcrumb('Бодлого')">
                <div>
                    <img src="assets/image/icons/news.svg" />&nbsp;&nbsp;&nbsp;Гуравдагч Баталгаажуулалт
                </div>
                <div>
                    <img src="assets/image/icons/arrow-right.svg" />
                </div>
            </mat-toolbar-row>
            <mat-toolbar-row class="d-flex justify-content-between align-items-center" routerLinkActive="active" routerLink="/sector" (click)="setBreadcrumb('Салбарын хаяг байршил')">
                <div>
                    <img src="assets/image/icons/news.svg" />&nbsp;&nbsp;&nbsp;Салбар
                </div>
                <div>
                    <img src="assets/image/icons/arrow-right.svg" />
                </div>
            </mat-toolbar-row>
            <mat-toolbar-row class="d-flex justify-content-between align-items-center" routerLinkActive="active" (click)="logout()">
                <div>
                    <img src="assets/image/icons/exit.svg" />&nbsp;&nbsp;&nbsp;Гарах
                </div>
                <div>
                    <img src="assets/image/icons/arrow-right.svg" />
                </div>
            </mat-toolbar-row>
        </mat-toolbar>
    </mat-sidenav>

    <mat-sidenav-content style="overflow-x: hidden; overflow-y: hidden;">
        <mat-toolbar style="background-color:#fff; filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));">
                &nbsp;&nbsp;&nbsp;<span style=" font-weight: 400; font-size: 14px; line-height: 19px; color: #9993AB;">{{breadcrumb}}</span>
        </mat-toolbar>
        <div class="main-content">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
