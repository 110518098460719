import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  // opened : boolean = false;
  breadcrumb: string = "";
  user: any = {};
  constructor(private toastr: ToastrService,
              private tokenStorage: TokenStorageService,
              private router: Router) { }

  ngOnInit(): void {
    this.user = this.tokenStorage.getUser();
    this.setBreadcrumb('Хяналтын самбар')
  }

  toggleMenu(){
    // this.opened = !this.opened;
  }

  setBreadcrumb(breadcrumb: any){
    this.breadcrumb = breadcrumb;
  }

  logout(){
    this.tokenStorage.signOut();
    this.router.navigate(['admin/login']);
  }

}
